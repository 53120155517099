<template>
  <div id="uploadFile">
    <el-upload
      class="upload-demo"
      action=""
      accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :auto-upload="false"
      multiple
      :on-change="handleChange"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.$emit("fileList", fileList);
    }
  }
};
</script>
<style lang="less"></style>
