<template>
  <div id="verification">
    <!-- 详情描述 -->
    <div class="public-item info">
      <div class="box">
        <p class="public-title">详情描述：</p>
        <div class="main">
          <textarea
            placeholder="描述此污染的具体信息"
            v-model="info"
          ></textarea>
        </div>
      </div>
    </div>
    <!-- 图片上传 -->
    <div class="public-item photo">
      <div class="box">
        <p class="public-title">图片上传：</p>
        <div class="main">
          <uploadImg v-on:imgFileList="getImgFileList" />
        </div>
      </div>
    </div>
    <!-- 附件上传 -->
    <div class="public-item file">
      <div class="box">
        <p class="public-title">附件上传：</p>
        <div class="main">
          <uploadFile v-on:fileList="getFileList" />
        </div>
      </div>
    </div>
    <p class="public-btn" @click="verSubmit">验证</p>
  </div>
</template>

<script>
import uploadImg from "../../components/uploadImg";
import uploadFile from "../../components/uploadFile";
import { formDataAxios } from "@/axios-init";
export default {
  data() {
    return {
      info: "",
      ImgFileList: [],
      fileList: []
    };
  },
  props: ["id"],
  components: {
    uploadImg,
    uploadFile
  },
  methods: {
    getImgFileList(data) {
      this.ImgFileList = data;
    },
    getFileList(data) {
      this.fileList = data;
    },
    verSubmit() {
      if (!this.ImgFileList.length) {
        this.$message.error("图片不能为空!");
        return;
      }
      if (!this.fileList.length) {
        this.$message.error("文件不能为空!");
        return;
      }
      var formData = new FormData();
      formData.append("Account", this.$store.state.user.account);
      formData.append("ID", this.id);
      formData.append("ValidInfo", this.info);
      formData.append("ImageCount", this.ImgFileList.length);
      formData.append("FileCount", this.fileList.length);
      this.ImgFileList.forEach((m, i) => {
        formData.append("ValidImage_" + i, m.raw);
      });
      this.fileList.forEach((m, i) => {
        formData.append("ValidFile_" + i, m.raw);
      });
      formData.append("isPC", "pc");

      formDataAxios
        .post("report/api/AddValid", formData)
        .then(response => {
          if (response.data.state) {
            this.$message.success(response.data.message);
            location.href = "/";
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
#verification {
  width: 1200px;
  margin: 18px auto 0;
  .public-item {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .box {
      width: calc(~"100% - 32px");
      .public-title {
        font-size: 16px;
        color: #408c72;
      }
      .main {
        margin-top: 12px;
      }
    }
    &.info {
      background: url("../../assets/47.png") 0px 2px no-repeat;
    }
    &.photo {
      background: url("../../assets/30.png") 0px 4px no-repeat;
    }
    &.file {
      background: url("../../assets/36.png") 0px 2px no-repeat;
    }
    &.info textarea {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      border: 1px solid #dadadb;
      background-color: #f8f9fd;
      height: 88px;
      resize: none;
      padding-top: 10px;
      &::-webkit-input-placeholder {
        color: #c7c7c7;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #c7c7c7;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #c7c7c7;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #c7c7c7;
      }
    }
  }
}
</style>
